import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../../components/Layout'
import ServiceFooter from '../../../components/ServiceFooter'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Gate = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`QT-net 会員の登録方法 | ${title}`}</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>QT-net 会員の登録方法</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /
          <li>
            <Link to={`/service/qtnetservice`}>
              駐車場利用者向けサービス「QT-netサービス」
            </Link>
          </li>
          /<li>QT-net 会員の登録方法</li>
        </div>
      </div>

      <div className="main-wrapper qtservice registration">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>QT-net 会員の登録方法</h2>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>
              QT-net
              に会員登録すると、対象の駐車場でスマホで料金精算ができたり、ポイントが貯まります。年会費は無料です。
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>目次</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <ul>
              <li>
                <AnchorLink to={`/service/qtnetservice/registration#top`}>
                  アプリからの会員登録
                </AnchorLink>
                <ul>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice/registration#download`}
                    >
                      アプリのダウンロード
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice/registration#registration`}
                    >
                      会員情報の登録
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to={`/service/qtnetservice/registration#card`}>
                      クレジットカード情報の登録
                    </AnchorLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div id="top" className="row">
          <div className="h-wrapper">
            <h3>アプリからの会員登録</h3>
          </div>
        </div>

        <div className="row">
          <div className="container empty"></div>
        </div>

        <div id="download" className="row">
          <div className="h-wrapper">
            <h3>1. アプリのダウンロード</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <div className="green-frame">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/qtnet-service/qt-logo.webp"
                />
                <img
                  className="qt-logo"
                  src="/assets/images/qtnet-service/qt-logo.png"
                  width="301"
                  height="116"
                  alt=""
                />
              </picture>
              <h5>
                「QT-net 駐車場ツール」を
                <br />
                いますぐダウンロード
              </h5>

              <div className="flex-wrap">
                <div className="flex-item">
                  <p className="flex-item-title">iPhone版</p>
                  <a
                    href="https://apps.apple.com/jp/app/qt-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E3%83%84%E3%83%BC%E3%83%AB/id563447907"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-apple.webp"
                      />
                      <img
                        className="dl-apple"
                        src="/assets/images/qtnet-service/dl-apple.png"
                        width="425"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
                <div className="flex-item">
                  <p className="flex-item-title">Andoroid版</p>
                  <a
                    href="https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool&hl=ja"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-google.webp"
                      />
                      <img
                        className="dl-google"
                        src="/assets/images/qtnet-service/dl-google.png"
                        width="402"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
              </div>

              <p className="note">
                ※対応OSは、iOSおよびAndroidTMです。ただし、一部非対応のバージョンおよび端末があります。
                <br />
                ※"iOS"、"App Store"はApple Inc.の商標です。
                <br />
                ※"AndroidTM"、"Google PlayTM"はGoogle
                Inc.の商標または登録商標です。
                <br />
                ※一部のサービスがご利用できない店舗がございます。
              </p>
            </div>
          </div>
        </div>

        <div id="registration" className="row">
          <div className="h-wrapper">
            <h3>2. 会員情報の登録</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>①アプリを起動し、新規登録するを選択</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-1.webp"
              />
              <img
                className="app-regi-1 aligncenter"
                src="/assets/images/qtnet-service/app-regi-1.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5>②登録作業の流れを確認し次へ</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-2.webp"
              />
              <img
                className="app-regi-2 aligncenter"
                src="/assets/images/qtnet-service/app-regi-2.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5>③規約内容を確認し同意する場合は次へ</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-3.webp"
              />
              <img
                className="app-regi-3 aligncenter"
                src="/assets/images/qtnet-service/app-regi-3.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5>④メールアドレスを入力し次へ</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-4.webp"
              />
              <img
                className="app-regi-4 aligncenter"
                src="/assets/images/qtnet-service/app-regi-4.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5>⑤メール送信完了の画面の注意事項を確認し確認ボタンを押す</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-5.webp"
              />
              <img
                className="app-regi-5 aligncenter"
                src="/assets/images/qtnet-service/app-regi-5.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5>
              ⑥登録が完了したら、左上の×ボタンを押して、ログイン画面へ遷移
            </h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-6.webp"
              />
              <img
                className="app-regi-6 aligncenter"
                src="/assets/images/qtnet-service/app-regi-6.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5>⑦メールを確認しURLをクリック</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-7.webp"
              />
              <img
                className="app-regi-7 aligncenter"
                src="/assets/images/qtnet-service/app-regi-7.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5>
              ⑧遷移先の会員登録継続画面で、メールに記載のセキュリティコード4桁を入力
            </h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-8.webp"
              />
              <img
                className="app-regi-8 aligncenter"
                src="/assets/images/qtnet-service/app-regi-8.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5>⑨アカウントを入力</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-9.webp"
              />
              <img
                className="app-regi-9 aligncenter"
                src="/assets/images/qtnet-service/app-regi-9.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5>⑩暗証番号を入力（2回）</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-10.webp"
              />
              <img
                className="app-regi-10 aligncenter"
                src="/assets/images/qtnet-service/app-regi-10.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5>
              ⑪すべての情報（会員情報と支払情報）登録の「次へ」を押して、情報入力画面へお進みください。
            </h5>
            <h6>
              ※後ほど、すべての情報が必要になるので、支払情報のみのご登録は非推奨です。
            </h6>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-11.webp"
              />
              <img
                className="app-regi-11 aligncenter"
                src="/assets/images/qtnet-service/app-regi-11.png"
                width="590"
                height="692"
                alt=""
              />
            </picture>

            <h5>⑫お客様情報を登録</h5>
            <h6>※住所は正確に建物名や部屋番号までご登録ください</h6>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-12.webp"
              />
              <img
                className="app-regi-12 aligncenter"
                src="/assets/images/qtnet-service/app-regi-12.png"
                width="590"
                height="905"
                alt=""
              />
            </picture>

            <h5>⑬内容確認</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-13.webp"
              />
              <img
                className="app-regi-13 aligncenter"
                src="/assets/images/qtnet-service/app-regi-13.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>⑭お客様情報登録完了で「クレジットカード」を押す</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/app-regi-14.webp"
              />
              <img
                className="app-regi-14 aligncenter"
                src="/assets/images/qtnet-service/app-regi-14.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div id="card" className="row">
          <div className="h-wrapper">
            <h3>3. クレジットカード情報の登録</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>カード番号と有効期限を登録してください</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/registration-2.webp"
              />
              <img
                className="card-1 aligncenter"
                src="/assets/images/qtnet-service/registration-2.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>アプリをダウンロード！</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <p className="sm12">
              駐車場の、検索/予約/精算ができる便利な駐車場アプリ。駐車場利用に応じてポイントが貯まり、お得に駐車場をご利用いただけます。
            </p>

            <div className="green-frame bottom">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/qtnet-service/qt-logo.webp"
                />
                <img
                  className="qt-logo"
                  src="/assets/images/qtnet-service/qt-logo.png"
                  width="301"
                  height="116"
                  alt=""
                />
              </picture>
              <h5>
                「QT-net 駐車場ツール」を
                <br />
                いますぐダウンロード
              </h5>

              <div className="flex-wrap">
                <div className="flex-item">
                  <p className="flex-item-title">iPhone版</p>
                  <a
                    href="https://apps.apple.com/jp/app/qt-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E3%83%84%E3%83%BC%E3%83%AB/id563447907"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-apple.webp"
                      />
                      <img
                        className="dl-apple"
                        src="/assets/images/qtnet-service/dl-apple.png"
                        width="425"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
                <div className="flex-item">
                  <p className="flex-item-title">Andoroid版</p>
                  <a
                    href="https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool&hl=ja"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-google.webp"
                      />
                      <img
                        className="dl-google"
                        src="/assets/images/qtnet-service/dl-google.png"
                        width="402"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
              </div>

              <p className="note">
                ※対応OSは、iOSおよびAndroidTMです。ただし、一部非対応のバージョンおよび端末があります。
                <br />
                ※"iOS"、"App Store"はApple Inc.の商標です。
                <br />
                ※"AndroidTM"、"Google PlayTM"はGoogle
                Inc.の商標または登録商標です。
                <br />
                ※一部のサービスがご利用できない店舗がございます。
              </p>
            </div>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Gate
